import React, { useState, useRef } from 'react';

import PropTypes from 'prop-types';

import * as U from '~/styles/utilities';
import useOutsideClick from '~/hooks/useOutsideClick';
import Header from './Header';
import Footer from './Footer';

import * as S from './styled';

const Layout = ({ children, noBanner, noHeader, noFooter, white }) => {
  const [open, setOpen] = useState(false);

  const node = useRef();

  useOutsideClick(node, () => {
    if (open) setOpen(false);
  });

  return (
    <S.Wrapper open={open}>

      {!noHeader && (
        <U.WrapperContent>
          <Header open={open} setOpen={setOpen} />
        </U.WrapperContent>
      )}
      <S.Container>{children}</S.Container>

      {!noFooter && <Footer />}
    </S.Wrapper>
  );
};

Layout.propTypes = {
  children: PropTypes.node,
  noBanner: PropTypes.bool,
};

export default Layout;
