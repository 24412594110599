import React from 'react';
import Grid from '@material-ui/core/Grid';

import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

import cnj from '~/assets/img/cnj.png';
import gamma from '~/assets/img/gamma.png';
import mh from '~/assets/img/mh.png';
import total from '~/assets/img/total.png';
import unimed from '~/assets/img/unimed.png';
import zab from '~/assets/img/zaab.png';
import infotec from '~/assets/img/infotec.png';

import * as S from './styled';

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1178 },
    items: 6,
  },
  tablet: {
    breakpoint: { max: 1178, min: 1000 },
    items: 4,
  },
  mobile: {
    breakpoint: { max: 1000, min: 0 },
    items: 2,
  },
};

const ClientsCompanies = () => (
  <S.ClientsSection>
    <Grid item xs={12}>
      <S.ClientsCompanyTitle>NOSSOS CLIENTES </S.ClientsCompanyTitle>
    </Grid>

    <Carousel
      arrows={false}
      ssr
      infinite
      partialVisbile
      responsive={responsive}
      autoPlay
    >
      <S.ClientsCompany src={cnj} alt="cnj" />
      <S.ClientsCompany src={gamma} alt="gamma" />
      <S.ClientsCompany src={mh} alt="mh" />
      <S.ClientsCompany src={total} alt="total" />
      <S.ClientsCompany src={unimed} alt="unimed" />
      <S.ClientsCompany src={zab} alt="zab" />
      <S.ClientsCompany src={infotec} alt="infotec" />
    </Carousel>
  </S.ClientsSection>
);

export default ClientsCompanies;
