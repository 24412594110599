import React, { useCallback, useEffect, useState } from 'react';

import * as U from '~/styles/utilities';
import { useCoursesContext } from '~/hooks/context/modules/CoursesContext';
import * as S from './styled';

import api from '../../services/api';

const FilterCourses = ({ title }) => {
  const { loadCourses, otcCourses } = useCoursesContext();

  const [categories, setCategories] = useState([]);

  const fetchData = useCallback(async () => {
    try {
      const responseCourses = await api.get('categories/');
      setCategories(responseCourses.data);
    } catch {
      setCategories([]);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleAreas = () => {
    const areas = categories.map((item) => {
      if (item.slug === 'power-bi') {
        return (
          <U.TitleSmall blue onClick={() => loadCourses(1)}>
            <U.CircleIcon />
            Power BI
          </U.TitleSmall>
        );
      }

      if (item.slug === 'tableau') {
        return (
          <U.TitleSmall orange onClick={() => loadCourses(4)}>
            <U.SquareIcon />
            Tableau
          </U.TitleSmall>
        );
      }

      if (item.slug === 'python') {
        return (
          <U.TitleSmall yellow onClick={() => loadCourses(2)}>
            <U.TriangleIcon />
            Python
          </U.TitleSmall>
        );
      }

      if (item.slug === 'excel') {
        return (
          <U.TitleSmall green onClick={() => loadCourses(3)}>
            <U.DiamondIcon />
            Excel
          </U.TitleSmall>
        );
      }

      return '';
    });

    return areas;
  };

  return (
    <S.Container>
      <U.TitleBig>{title}</U.TitleBig>

      <S.WrapperFilters>
        {categories.length !== 1 && <S.TextFilter>filtrar por:</S.TextFilter>}

        {handleAreas()}
      </S.WrapperFilters>
    </S.Container>
  );
};

export default FilterCourses;
