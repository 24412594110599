import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';

import NotFound from '~/pages/NotFound';

import CertificateCareerAcelerator from '~/pages/Certificate/acelerador-carreira';
import CertificatePythonWeek from '~/pages/Certificate/semana-python';
import CertificateExcelWeek from '~/pages/Certificate/semana-excel';
import CertificateEspanhol from '~/pages/Certificate/espanhol';
import CertificateEspanholExcel from '~/pages/Certificate/espanhol-excel';
import CertificateIaWeek from '~/pages/Certificate/semana-ia';

const Routes = () => (
  <Route
    render={({ location }) => (
      <AnimatePresence exitBeforeEnter>
        <Switch location={location} key={location.pathname}>
          <Route path="/certificado-python" component={CertificatePythonWeek} />
          <Route path="/certificado-excel" component={CertificateExcelWeek} />
          <Route path="/certificado" component={CertificateCareerAcelerator} />
          <Route path="/latam" component={CertificateEspanhol} />
          <Route path="/latam-excel" component={CertificateEspanholExcel} />
          <Route path="/certificado-ia" component={CertificateIaWeek} />
          <Route path="*" component={NotFound} />
        </Switch>
      </AnimatePresence>
    )}
  />
);

export default Routes;
