import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { motion } from 'framer-motion';
import chevronDown from '~/assets/img/chevron-dawn.png';
import * as ds from './theme';


const formStyles = ({ theme, noBackground }) => css`
  ${!noBackground &&
  `background: ${theme.colors.gray5};
  border: 1px solid ${theme.colors.gray6};`}

  border-radius: 4px;

  opacity: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: left;
`;

const formContentStyles = ({ padding }) => css`
  padding: ${padding ? `${padding}` : '38px 32px 50px'};
`;

const InputStyles = ({ theme }) => css`
  width: 100%;
  background: ${theme.colors.gray7};
  font-family: ${theme.font.montserrat};
  border: 1px solid ${theme.colors.gray6};
  border-radius: 4px;
  padding: 14px;
  font-weight: 500;

  color: ${theme.colors.white};
  font-size: ${theme.typography.sm};

  &::placeholder {
    color: ${theme.colors.white};
  }
`;

const labelStyles = ({ theme, color }) => css`
  color: ${color || theme.colors.lightblue};
  font-size: ${theme.typography.xs};
  letter-spacing: 2.22px;
  line-height: 31px;
  font-weight: 500;
`;

const sectionFormStyles = () => css`
  position: relative;
`;

const selectStyles = ({ theme }) => css`
  width: 100%;
  background: ${theme.colors.gray7};
  font-family: ${theme.font.montserrat};
  border: 1px solid ${theme.colors.gray6};
  border-radius: 4px;
  padding: 14px;
  font-weight: 500;
  background-image: url(${chevronDown});
  background-repeat: no-repeat;
  background-size: 20px;
  background-position: center right 20px;
  -webkit-appearance: none;

  color: ${theme.colors.white};
  font-size: ${theme.typography.sm};

  &:not(:checked) {
    color: ${theme.colors.white};
    font-weight: 500;
  }

  option {
    color: ${theme.colors.white};
    font-size: ${theme.typography.sm};
  }
`;

const textAreaStyles = ({ theme }) => css`
  width: 100%;
  background: ${theme.colors.gray7};
  font-family: ${theme.font.montserrat};
  border: 1px solid ${theme.colors.gray6};
  border-radius: 4px;
  padding: 14px;
  font-weight: 500;

  color: ${theme.colors.white};
  font-size: ${theme.typography.sm};

  &::placeholder {
    color: ${theme.colors.white};
  }
`;

const formButton = ({ theme, full }) => css`
  background: ${theme.colors.blue};
  color: ${theme.colors.white};
  border-radius: 4px;
  padding: 11px 90px;
  font-size: ${theme.typography.md};
  font-weight: 500;
  border: 0;
  width: ${full && '100%'};

  @media (max-width: ${theme.breaks.medium}) {
    width: 100%;
  }
`;

const afterSpan = css`
  left: 4px;
  top: 2px;
  width: 3px;
  height: 5px;
  border: solid ${ds.default.colors.background};
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
`;

const labelCheckboxStyles = ({ theme }) => css`
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  @media (min-width: ${theme.breaks.medium1}) {
    cursor: pointer;
  }
  font-size: 22px;
  user-select: none;

  input {
    position: absolute;
    opacity: 0;
    @media (min-width: ${theme.breaks.medium1}) {
      cursor: pointer;
    }
    height: 0;
    width: 0;
  }

  input:checked ~ span {
    background-color: ${theme.colors.lightblue};
  }

  span {
    position: absolute;
    top: 2px;
    left: 0;
    height: 13px;
    width: 13px;
    background: ${theme.colors.gray7};
    border-radius: 2px;
  }

  span:after {
    content: '';
    position: absolute;
    display: none;
  }

  input:checked ~ span:after {
    display: block;
  }

  &:after {
    ${afterSpan}
  }

  span:after {
    ${afterSpan}
  }

  p {
    font-size: ${theme.typography.sm};
    color: ${theme.colors.lightblue};
    font-weight: 500;
  }
`;

const hintStyles = ({ theme, error }) => css`
  color: ${error ? theme.colors.red : theme.colors.white};

  font-size: 11px;

  letter-spacing: 0.5px;
  padding-top: 4px;
  font-weight: 600;
`;

export const Form = styled('div')(formStyles);
export const FormContent = styled('div')(formContentStyles);
export const Label = styled('p')(labelStyles);
export const FormSection = styled('section')(sectionFormStyles);
export const Select = styled('select')(selectStyles);
export const TextArea = styled('textarea')(textAreaStyles);
export const ButtonForm = styled(motion.button)(formButton);
export const Input = styled('input')(InputStyles);
export const LabelCheckbox = styled('label')(labelCheckboxStyles);
export const Hint = styled('p')(hintStyles);
