import React from 'react';
import { IoMdClose } from 'react-icons/io';

import * as U from '~/styles/utilities';
import * as S from './styled';

const Modal = ({
  onClose,
  children,
  full,
  fullresp,
  height,
  noBackground,
  size,
  hasButton,
  textHeader,
  contentPadding,
  white,
}) => (
  <S.Modal noBackground={noBackground}>
    <S.ModalContainer
      full={full}
      fullresp={fullresp}
      size={size}
      noBackground={noBackground}
    >
      {!!hasButton && (
        <S.ButtonClose onClick={onClose} dark={white}>
          <IoMdClose size={25} />
        </S.ButtonClose>
      )}
      <S.ModalContent
        height={height}
        contentPadding={contentPadding}
        white={white}
      >
        {textHeader && (
          <U.Text
            white
            bold
            style={{ position: 'relative', top: -20, marginRight: 25 }}
          >
            {textHeader}
          </U.Text>
        )}
        {children}
      </S.ModalContent>
    </S.ModalContainer>
  </S.Modal>
);

export default Modal;
