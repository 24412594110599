import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { motion } from 'framer-motion';
import { Formik } from 'formik';
import * as yup from 'yup';

import { GrLinkedin } from 'react-icons/gr';
import { FaDownload } from 'react-icons/fa';
import { animateScroll as scroll } from 'react-scroll';
import { backVariants, fade } from '~/styles/animations';
import { useUserContext } from '~/hooks/context/modules/UserContext';
import Loading from '~/components/Loading';
import useWindowDimensions from '~/hooks/useWindowDimensions';

import { Modal, Toast } from '~/components';

import notebook from '~/assets/img/notebook-img.png';
import logoAcelerador from '~/assets/img/logo-espanhol.png';

import api from '~/services/api';

import * as U from '~/styles/utilities';
import * as F from '~/styles/form';
import * as S from './styled';

const Certificate = () => {
  useEffect(() => {
    scroll.scrollToTop();
  }, []);
  const { width } = useWindowDimensions();

  const [loading, setLoading] = useState({ form: false, image: false });

  const [modalCertificate, setModalCertificate] = useState(false);
  const [CertificateData, setCertificateData] = useState({});

  const handleSubmitForm = async (data) => {
    const formattedData = { ...data, key_word: 'latam' };
    try {
      setLoading({ form: true, image: true });
      const response = await api.post('/generate-certificate', formattedData);

      setModalCertificate(true);
      setCertificateData(response.data);
      setLoading({ form: false, image: false });
    } catch (err) {
      Toast.error('Ocorreu um erro no sistema, favor tentar novamente!');
      setLoading({ form: false, image: false });
    }
  };

  // form

  const initialValues = {
    username: '',
    email: '',
    key_word: '',
  };

  const validations = () =>
    yup.object().shape({
      username: yup.string().required('El nombre es obligatorio'),

      email: yup
        .string()
        .email('Introduzca un correo electrónico válido')
        .required('El correo electrónico es obligatorio.'),
    });

  return (
    <>
      <motion.div exit={{ opacity: 0 }} initial="initial" animate="animate">
        <S.Background>
          <S.ContainerLimit>
            <U.Gap size={50} />
            <Grid container xs={12} justify="center">
              <Grid item xs={12} sm={4}>
                <img
                  src={logoAcelerador}
                  alt="logo"
                  style={{ width: '100%' }}
                />
              </Grid>
            </Grid>
            <U.Gap size={100} />
            <Grid container xs={12}>
              <Grid item xs={12} md={6}>
                <Grid xs={12} md={10}>
                  <motion.div variants={fade}>
                    <S.Title>
                        <span>Felicitaciones</span> por llegar al final del evento
                    </S.Title>
                    <U.Gap size={12} />
                    <S.Text>
                        Ahora es el momento de emitir tu certificado
                    </S.Text>
                    <S.Text>Aprovecha este momento para:</S.Text>
                    <U.Gap size={25} />
                    <Grid xs={12} sm={10}>
                      <S.UList>
                        <li>
                            Comparte tu certificado en <span>LinkedIN</span>
                        </li>
                        <li>
                            Siga a <span>ZAKIDATA</span> en las redes sociales
                        </li>
                        <li>
                            Conozca nuestro{' '}
                            <span>Formación Completa en Power BI</span> (revisar la
                           próxima fecha de clase)
                        </li>
                      </S.UList>
                    </Grid>
                    <U.Gap size={30}>
                      <S.Text>
                        ¡Gracias por seguirnos hasta aquí!
                      </S.Text>
                    </U.Gap>
                  </motion.div>
                  <Grid container xs={12} justify="center">
                    <Grid item xs={12} sm={6} md={10}>
                      <motion.div variants={fade}>
                        <img
                          src={notebook}
                          alt="logo"
                          style={{ width: '100%' }}
                        />
                      </motion.div>
                    </Grid>
                  </Grid>
                  <U.Gap size={30} />
                </Grid>
              </Grid>

              <Grid item xs={12} md={6}>
                <motion.div initial="exit" animate="enter" exit="exit">
                  <motion.div variants={backVariants}>
                    <S.Card>
                      <Grid
                        container
                        justify="space-between"
                        alignItems="center"
                      >
                        <Grid item>
                          <S.TitleModal>
                            <span>Emite tu </span> certificado
                          </S.TitleModal>
                        </Grid>
                      </Grid>
                      <U.Gap size={20} />
                      <Formik
                        initialValues={initialValues}
                        onSubmit={handleSubmitForm}
                        validationSchema={validations()}
                      >
                        {({
                          handleSubmit,
                          handleBlur,
                          handleChange,
                          values,
                          errors,
                          touched,
                          setFieldValue,
                        }) => (
                          <>
                            <form onSubmit={handleSubmit}>
                              <Grid container spacing={2}>
                                <Grid item xs={12}>
                                  <F.Label>Nombre (máx. 30 caracteres)</F.Label>
                                  <F.Input
                                    type="text"
                                    placeholder="Escriba su nombre"
                                    name="username"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.username}
                                    maxLength={30}
                                  />
                                  {errors.username && touched.username ? (
                                    <F.Hint
                                      error={
                                        !!errors.username && touched.username
                                      }
                                    >
                                      {errors.username}
                                    </F.Hint>
                                  ) : null}
                                </Grid>
                                <Grid item xs={12}>
                                  <F.Label>Correo electrónico registrado para el evento.</F.Label>
                                  <F.Input
                                    type="email"
                                    placeholder="Escribe tu correo electrónico"
                                    name="email"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.email}
                                  />
                                  {errors.email && touched.email ? (
                                    <F.Hint
                                      error={!!errors.email && touched.email}
                                    >
                                      {errors.email}
                                    </F.Hint>
                                  ) : null}
                                </Grid>

                                <Grid
                                  item
                                  xs={12}
                                  container
                                  justify="flex-end"
                                  style={{ margin: '40px 0 32px 0px' }}
                                >
                                  <S.Button
                                    // whileHover={{ scale: 1.05 }}
                                    whileTap={{ scale: 0.95 }}
                                    full
                                    type="submit"
                                    className="flex-center"
                                    disabled={loading.form}
                                  >
                                    {loading.form ? (
                                      <>
                                        <span>Emitiendo el certificado ...</span>
                                        <Loading />
                                      </>
                                    ) : (
                                      'Emite tu Certificado!'
                                    )}
                                  </S.Button>
                                </Grid>
                              </Grid>
                            </form>
                          </>
                        )}
                      </Formik>
                    </S.Card>
                  </motion.div>
                </motion.div>
              </Grid>
            </Grid>
            <U.Gap size={100} />
          </S.ContainerLimit>
        </S.Background>

        <S.SeeMoreSection>
          <S.ContainerLimit>
            <Grid container justify="center" className="text-center">
              <Grid>
                <S.TitleBig>Ver más sobre </S.TitleBig>
                <S.TitleBig>
                  <span>ZAKIDATA</span>
                </S.TitleBig>
              </Grid>
            </Grid>
            <U.Gap size={50} />
            <Grid container xs={12} justify="center">
              <Grid item container xs={12} spacing={3}>
                <Grid item xs={12} lg={4}>
                  <a
                    href="https://www.aceleradorpowerbi.com/master-powerbi"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <S.ButtonFooter whileTap={{ scale: 0.95 }}>
                        Curso completo de Power BI
                    </S.ButtonFooter>
                  </a>
                </Grid>
                <Grid item xs={12} lg={4}>
                  <a
                    href="https://www.instagram.com/profe.zaki/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <S.ButtonFooter whileTap={{ scale: 0.95 }}>
                      Instagram
                    </S.ButtonFooter>
                  </a>
                </Grid>
                <Grid item xs={12} lg={4}>
                  <a
                    href="https://youtube.com/@profezaki?si=WsHzAPLVFzTF6kbT"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <S.ButtonFooter whileTap={{ scale: 0.95 }}>
                      Youtube
                    </S.ButtonFooter>
                  </a>
                </Grid>
              </Grid>
            </Grid>
          </S.ContainerLimit>
        </S.SeeMoreSection>

        <Grid container justify="center" className="text-center">
          <Grid>
            <U.Gap size={20} />

            <S.Text>ZAKIDATA © 2024</S.Text>
            <U.Text>Todos los derechos reservados</U.Text>
            <U.Gap size={20} />
          </Grid>
        </Grid>
      </motion.div>

      {modalCertificate && (
        <Modal
          onClose={() => setModalCertificate(false)}
          hasButton
          size={70}
          white
          contentPadding={width > 768 ? '2rem' : '4rem 1.5rem 1.5rem 1.5rem'}
        >
          <Grid container spacing={3}>
            <Grid
              item
              xs={12}
              md={8}
              container
              alignItems="center"
              justify="center"
            >
              {!loading.image && (
                <Grid
                  item
                  xs={12}
                  md={8}
                  container
                  alignItems="center"
                  justify="center"
                  style={{ height: 460 }}
                >
                  <Loading />
                </Grid>
              )}
              <img
                src={CertificateData.certificate_image}
                alt="certificado"
                style={loading.image ? { width: '100%' } : { display: 'none' }}
                onLoad={() => setLoading({ form: false, image: true })}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <U.Gap size={width > 768 ? 50 : 0} />
              <S.TitleCertificate>
                Parabéns, {CertificateData.name}!
              </S.TitleCertificate>
              <U.Gap size={width > 768 ? 20 : 10} />
              <U.Text black bold>
                ¡Disfruta y comparte más este logro en tu Linkedin!
              </U.Text>

              <U.Gap size={30} />
              <a
                href={`https://www.linkedin.com/shareArticle?mini=true&url=${CertificateData.certificate_image}`}
                target="_blank"
                rel="noreferrer"
              >
                <S.ButtonFooter whileTap={{ scale: 0.95 }}>
                  <GrLinkedin size={20} color="#fff" /> Compartir en linkedIn
                </S.ButtonFooter>
              </a>
              <U.Gap size={20} />
              <a
                href={CertificateData.certificate}
                download="certificado.pdf"
                target="_blank"
                rel="noreferrer"
              >
                <S.ButtonFooter whileTap={{ scale: 0.95 }}>
                  <FaDownload size={20} color="#fff" /> Descargar certificado
                </S.ButtonFooter>
              </a>
            </Grid>
          </Grid>
        </Modal>
      )}
    </>
  );
};

export default Certificate;
