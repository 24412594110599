import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FaArrowRight } from 'react-icons/fa';
import { animateScroll as scroll } from 'react-scroll';
import {
  circ01,
  circ02,
  quad01,
  quad02,
  tri01,
  tri02,
  diamond1,
  diamond2,
  powerbi,
  tableau,
  python,
  excel,
} from '~/assets/img';
import { useCheckoutContext } from '~/hooks/context/modules/CheckoutContext';
import { useCoursesContext } from '~/hooks/context/modules/CoursesContext';
import * as U from '~/styles/utilities';
import * as S from './styled';

const CardCourse = ({ id, title, description, shape, checkout, data }) => {
  const [active, setActive] = useState(false);

  const { addToCart } = useCheckoutContext();
  const { qtdCourses } = useCoursesContext();

  const isSubscription = data?.type === 'subscription';
  const isSoon = data?.soon;

  const Route = (isSubscription && `/trilha/${data.slug}`) || `/curso/${id}`;

  return (
    <S.Wrapper>
      <S.ImageBackground
        src={
          (shape === 'circle' && circ01) ||
          (shape === 'square' && quad01) ||
          (shape === 'triangle' && tri01) ||
          (shape === 'diamond' && diamond1)
        }
        alt="circ01"
      />
      <S.ImageBackgroundHover
        src={
          (shape === 'circle' && circ02) ||
          (shape === 'square' && quad02) ||
          (shape === 'triangle' && tri02) ||
          (shape === 'diamond' && diamond2)
        }
        alt="circ02"
        active={active}
      />
      <S.CardWrapper>
        <S.Card
          onMouseEnter={() => setActive(true)}
          onMouseLeave={() => setActive(false)}
        >
          <Link to={!isSoon && Route}>
            <S.Title type={shape}>{title}</S.Title>
            <S.CourseImg
              src={
                (shape === 'circle' && powerbi) ||
                (shape === 'square' && tableau) ||
                (shape === 'triangle' && python) ||
                (shape === 'diamond' && excel)
              }
              alt="course"
            />
            <S.CardText>{description}</S.CardText>
          </Link>

          {isSubscription ? (
            <S.LinkPurchase to={`/inscricao/${data.slug}`}>
              {isSoon ? 'Cadastrar-se na lista de espera' : 'Inscreva-se agora'}
            </S.LinkPurchase>
          ) : (
            <S.LinkPurchase
              to="/carrinho"
              onClick={
                checkout
                  ? () => {
                      addToCart(data);
                      scroll.scrollToTop();
                    }
                  : () => addToCart(data)
              }
            >
              {checkout ? '+ Adicionar ao carrinho' : 'Comprar agora'}
            </S.LinkPurchase>
          )}

          {isSoon ? (
            <S.CardInfo>
              <S.Title type={shape}>EM BREVE</S.Title>
            </S.CardInfo>
          ) : (
            <Link to={!isSoon && Route}>
              <S.CardInfo type={shape}>
                <p>
                  {isSubscription
                    ? 'Acessar'
                    : `+${qtdCourses(data.modules)} aulas em ${
                        data.workload
                      }h de curso`}
                </p>
                <U.ButtonHoverAnimate
                  whileHover={{ scale: 1.3 }}
                  whileTap={{ scale: 0.95 }}
                >
                  <FaArrowRight size={20} />
                </U.ButtonHoverAnimate>
              </S.CardInfo>
            </Link>
          )}
        </S.Card>
      </S.CardWrapper>
    </S.Wrapper>
  );
};

export default CardCourse;
