import React, { useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import { motion } from 'framer-motion';
import imgPresent from '~/assets/img/empowerdata-p.png';
import { Helmet } from 'react-helmet';
import Routes from './routes';
import { fade } from './styles/animations';
import './styles/styles.css';
import * as U from './styles/utilities';


const App = () => {
  const [showCookie, setShowCookie] = useState(() => {
    const cookie = localStorage.getItem('cookie');

    if (cookie) {
      return cookie;
    }

    return 'notAccepted';
  });

  const handleAcceptCookie = () => {
    setShowCookie(false);
    localStorage.setItem('cookie', 'accepted');
  };
  return (
    <BrowserRouter>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Empowerdata</title>
        <meta property="og:title" content="Empowerdata" />
        <meta property="og:image" content={imgPresent} />
        <meta
          property="og:description"
          content="Venha se profissionalizar na área de análise de dados, Business Intelligence & Analytics."
        />
      </Helmet>
      <Routes />
      {showCookie === 'notAccepted' && (
        <motion.div exit={{ opacity: 0 }} initial="initial" animate="animate">
          <motion.div variants={fade}>
            <Grid container justify="center">
              <U.CookiesBar>
                <Grid container alignItems="center">
                  <Grid item xs={12} md={9} lg={10}>
                    <p>
                      Olá, esse aviso é para te informar que utilizamos cookies
                      e outras tecnologias semelhantes para analisar sua jornada
                      em nosso site. Assim te entregamos uma melhor experiência
                      e conteúdos personalizados, de acordo com a nossa Política
                      de Privacidade. Ao continuar navegando, você concorda com
                      estas condições. <a href="#test">Saiba mais</a>
                    </p>
                  </Grid>
                  <Grid item xs={12} md={3} lg={2} style={{ textAlign: 'end' }}>
                    <U.ButtonBlue
                      whileHover={{ scale: 1.05 }}
                      whileTap={{ scale: 0.95 }}
                      onClick={handleAcceptCookie}
                    >
                      Ok, continuar
                    </U.ButtonBlue>
                  </Grid>
                </Grid>
              </U.CookiesBar>
            </Grid>
          </motion.div>
        </motion.div>
      )}
    </BrowserRouter>
  );
};
export default App;
